html, body, #root, #root>div {
    height: 100%;
}

.react-datepicker__input-container {
    width: inherit;
}

.react-datepicker-wrapper {
    width: 100%;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: Arial;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
    width: device-width;
}


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    text-align: left;
    background-color: #ffffff;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}
